import React, { useState, useEffect } from "react";
import { Form, FormGroup, Input, Row, Col, CardBody, Container, Button, Alert } from "reactstrap";
import { MdCancel } from "react-icons/md";
import { createArticle, createAuthor, fetchAuthors } from "./articleApi";

function AddArticleForm() {
  const [authorFields, setAuthorFields] = useState([{ id: 1 }]);
  const [authors, setAuthors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    user: { id: localStorage.getItem("uid") },
    articleComponent: "",
    title: "",
    abstractContent: "",
    keywords: "",
    file: "",
    msgToPublisher: "",
    authors: [
      {
        name: "",
        qualification: "",
        designation: "",
        institute: "",
        email: "",
        contact: "",
      },
    ],
    contributor: "",
    dateOfSubmission: "",
    expectedPublishDate: "",
  });

  useEffect(() => {
    const loadAuthors = async () => {
      try {
        const fetchedAuthors = await fetchAuthors();
        setAuthors(fetchedAuthors);
      } catch (error) {
        console.error("Failed to fetch authors:", error);
      }
    };
    loadAuthors();
  }, []);

  const validateForm = () => {
    let errors = [];

    // Validate article fields
    if (!formData.articleComponent) errors.push("Article Name is required.");
    if (!formData.title) errors.push("Title is required.");
    if (!formData.abstractContent) errors.push("abstractContent is required.");
    if (!formData.keywords) errors.push("Keywords are required.");
    //if (!formData.file) errors.push("File is required.");

    // Validate authors fields
    formData.authors.forEach((author, index) => {
      if (!author.name) errors.push(`Author ${index + 1}: Name is required.`);
      if (!author.qualification) errors.push(`Author ${index + 1}: Qualification is required.`);
      if (!author.designation) errors.push(`Author ${index + 1}: Designation is required.`);
      if (!author.email) errors.push(`Author ${index + 1}: Email is required.`);
      if (!author.contact) errors.push(`Author ${index + 1}: Contact is required.`);
    });

    if (errors.length > 0) {
      setErrorMessage(errors.join(" "));
      return false;
    }
    return true;
  };

  const addAuthorFields = () => {
    if (authorFields.length < 4) {
      setAuthorFields([...authorFields, { id: authorFields.length + 1 }]);
      setFormData({
        ...formData,
        authors: [
          ...formData.authors,
          { name: "", qualification: "", designation: "", institute: "", email: "", contact: "" },
        ],
      });
    }
  };

  const handleCancel = (id) => {
    setAuthorFields(authorFields.filter((field) => field.id !== id));
    setFormData({
      ...formData,
      authors: formData.authors.filter((_, index) => index !== id - 1),
    });
  };

  const handleInputChange = (e, fieldName) => {
    if (fieldName === "file") {
      setFormData({ ...formData, [fieldName]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [fieldName]: e.target.value });
    }
  };

  const handleAuthorInputChange = (index, fieldName, value) => {
    const updatedAuthors = [...formData.authors];
    updatedAuthors[index][fieldName] = value;
    setFormData({ ...formData, authors: updatedAuthors });
  };

  const fileInputRef = React.createRef();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setSuccessMessage("");
    setErrorMessage("");

    if (!validateForm()) {
      setIsSubmitting(false);
      // Hide error message after 3 seconds
      setTimeout(() => setErrorMessage(""), 3000);
      return;
    }

    try {
      // Save authors to API

      await createArticle(formData, formData.file);

      // Simulate form submission logic for article (replace with your article submission logic)
      setSuccessMessage("Article submitted successfully!");
      setIsSubmitting(false);
      // Hide success message after 3 seconds
      setTimeout(() => setSuccessMessage(""), 3000);

      // Reset form data after successful submission
      setFormData({
        articleComponent: "",
        title: "",
        abstractContent: "",
        keywords: "",
        file: null,
        msgToPublisher: "",
        authors: [
          { name: "", qualification: "", designation: "", institute: "", email: "", contact: "" },
        ],
        contributor: "",
        dateOfSubmission: "",
        expectedPublishDate: "",
      });
      setAuthorFields([{ id: 1 }]);
      // Reset the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Reset the file input
      }
    } catch (error) {
      console.error("Error submitting article:", error);
      setErrorMessage("Failed to submit article. Please try again.");
      setIsSubmitting(false);
      // Hide error message after 3 seconds
      setTimeout(() => setErrorMessage(""), 3000);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#f7f7f7",
        padding: "20px",
        borderRadius: "8px",
        margin: "10px",
      }}
    >
      <Container fluid>
        <Row>
          <Col xl={12}>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <h6 className="heading-small text-muted mb-4">Article Information</h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-articleComponent"
                          style={{ fontSize: "0.875rem" }}
                        >
                          Article Component <span className="text-danger">*</span>
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-articleComponent"
                          placeholder="Enter Article Name"
                          type="text"
                          value={formData.articleComponent}
                          onChange={(e) => handleInputChange(e, "articleComponent")}
                          style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-title"
                          style={{ fontSize: "0.875rem" }}
                        >
                          Title <span className="text-danger">*</span>
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-title"
                          placeholder="Enter Title"
                          type="text"
                          value={formData.title}
                          onChange={(e) => handleInputChange(e, "title")}
                          style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label style={{ fontSize: "0.875rem" }}>
                        abstractContent <span className="text-danger">*</span>
                      </label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Description"
                        rows="4"
                        type="textarea"
                        value={formData.abstractContent}
                        onChange={(e) => handleInputChange(e, "abstractContent")}
                        style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                        required
                      />
                    </FormGroup>
                  </div>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-keywords"
                          style={{ fontSize: "0.875rem" }}
                        >
                          Keywords <span className="text-danger">*</span>
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-keywords"
                          placeholder="Keywords"
                          type="text"
                          value={formData.keywords}
                          onChange={(e) => handleInputChange(e, "keywords")}
                          style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-file"
                          style={{ fontSize: "0.875rem" }}
                        >
                          File <span className="text-danger">*</span>
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="file"
                          accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          name="fileToUpload"
                          placeholder="No File Chosen"
                          type="file"
                          onChange={(e) => handleInputChange(e, "file")}
                          style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                {/* Additional form fields */}

                <div className="pl-lg-4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-msgToPublisher"
                      style={{ fontSize: "0.875rem" }}
                    >
                      Message to Publisher <span className="text-danger">*</span>
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-msgToPublisher"
                      placeholder="Enter Message to Publisher"
                      rows="2"
                      type="textarea"
                      value={formData.msgToPublisher}
                      onChange={(e) => handleInputChange(e, "msgToPublisher")}
                      style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                    />
                  </FormGroup>
                </div>

                <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4" style={{ fontSize: "0.875rem" }}>
                  Author’s
                </h6>
                {authorFields.map((field, index) => (
                  <div key={field.id} className="pl-lg-4">
                    <h6 className="heading-small text-muted mb-4" style={{ fontSize: "0.875rem" }}>
                      {index === 0 ? "Author 1" : `Author ${index + 1}`}
                      {index !== 0 && (
                        <MdCancel
                          onClick={() => handleCancel(field.id)}
                          style={{
                            cursor: "pointer",
                            color: "red",
                            fontSize: "1rem",
                            marginLeft: "10px",
                          }}
                        />
                      )}
                    </h6>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor={`input-authorname-${field.id}`}
                            style={{ fontSize: "0.875rem" }}
                          >
                            Name of Author <span className="text-danger">*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id={`input-authorname-${field.id}`}
                            placeholder="Enter Name of Author"
                            type="text"
                            value={formData.authors[field.id - 1].name}
                            onChange={(e) =>
                              handleAuthorInputChange(field.id - 1, "name", e.target.value)
                            }
                            style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor={`input-qualification-${field.id}`}
                            style={{ fontSize: "0.875rem" }}
                          >
                            Qualification <span className="text-danger">*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id={`input-qualification-${field.id}`}
                            placeholder="Enter Qualification"
                            type="text"
                            value={formData.authors[field.id - 1].qualification}
                            onChange={(e) =>
                              handleAuthorInputChange(field.id - 1, "qualification", e.target.value)
                            }
                            style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor={`input-designation-${field.id}`}
                            style={{ fontSize: "0.875rem" }}
                          >
                            Designation <span className="text-danger">*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id={`input-designation-${field.id}`}
                            placeholder="Enter Designation"
                            type="text"
                            value={formData.authors[field.id - 1].designation}
                            onChange={(e) =>
                              handleAuthorInputChange(field.id - 1, "designation", e.target.value)
                            }
                            style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor={`input-authorname-${field.id}`}
                            style={{ fontSize: "0.875rem" }}
                          >
                            Name of Institute
                          </label>
                          <Input
                            className="form-control-alternative"
                            id={`input-authorname-${field.id}`}
                            placeholder="Enter Name of Institute"
                            type="text"
                            value={formData.authors[field.id - 1].institute}
                            onChange={(e) =>
                              handleAuthorInputChange(field.id - 1, "institute", e.target.value)
                            }
                            style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor={`input-email-${field.id}`}
                            style={{ fontSize: "0.875rem" }}
                          >
                            Email <span className="text-danger">*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id={`input-email-${field.id}`}
                            placeholder="Enter Email"
                            type="email"
                            value={formData.authors[field.id - 1].email}
                            onChange={(e) =>
                              handleAuthorInputChange(field.id - 1, "email", e.target.value)
                            }
                            style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor={`input-contact-${field.id}`}
                            style={{ fontSize: "0.875rem" }}
                          >
                            Contact No <span className="text-danger">*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id={`input-contact-${field.id}`}
                            placeholder="Enter Contact No"
                            type="tel"
                            value={formData.authors[field.id - 1].contact}
                            onChange={(e) =>
                              handleAuthorInputChange(field.id - 1, "contact", e.target.value)
                            }
                            style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                ))}
                {authorFields.length < 4 && (
                  <Button
                    onClick={addAuthorFields}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                  >
                    Add More Authors
                  </Button>
                )}

                <hr className="my-4" />
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-contributorname"
                        style={{ fontSize: "0.875rem" }}
                      >
                        Financial Contributor or Sponsor Agency{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-contributorname"
                        placeholder="Enter Contributor or Sponsor Agency"
                        type="text"
                        value={formData.contributor}
                        onChange={(e) => handleInputChange(e, "contributor")}
                        style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-date"
                        style={{ fontSize: "0.875rem" }}
                      >
                        Date of Submission <span className="text-danger">*</span>
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-date"
                        placeholder="Enter Date"
                        type="date"
                        value={formData.dateOfSubmission}
                        onChange={(e) => handleInputChange(e, "dateOfSubmission")}
                        style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-month-year"
                        style={{ fontSize: "0.875rem" }}
                      >
                        Expected date for publishing the article{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-month-year"
                        placeholder="Enter date"
                        type="date"
                        value={formData.expectedPublishDate}
                        onChange={(e) => handleInputChange(e, "expectedPublishDate")}
                        style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div className="d-flex justify-content-center mt-4">
                  <Button color="success" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </Button>
                </div>
              </Form>
              {successMessage && <Alert color="success">{successMessage}</Alert>}
              {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            </CardBody>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AddArticleForm;
