import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CoverLayout from "layouts/contact/components/CoverLayout";
import bgImage from "assets/images/bg-reset-cover.jpeg";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Swal from "sweetalert2"; // Import SweetAlert2

function Basic() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });
  const [formError, setFormError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();

  // Email validation
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Contact number validation (10 digits only)
  const validateContact = (contact) => {
    const contactRegex = /^\d{10}$/;
    return contactRegex.test(contact);
  };

  // Remove special characters from name and message
  // Allow only letters and whitespace for name and message
  const sanitizeInput = (input) => {
    return input.replace(/[^a-zA-Z\s]/g, "");
  };

  useEffect(() => {
    if (isSubmitted) {
      const timer = setTimeout(() => {
        setIsSubmitted(false);
      }, 3000); // Hide message after 3 seconds
      return () => clearTimeout(timer); // Cleanup if component unmounts
    }
  }, [isSubmitted]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "name" || name === "message") {
      setFormData({ ...formData, [name]: sanitizeInput(value) });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError("");

    // Check for empty fields
    if (!formData.name || !formData.email || !formData.contact || !formData.message) {
      setFormError("Please fill in all fields.");
      return;
    }

    // Validate email and contact
    if (!validateEmail(formData.email)) {
      setFormError("Please enter a valid email address.");
      return;
    }

    if (!validateContact(formData.contact)) {
      setFormError("Contact number must be 10 digits.");
      return;
    }

    try {
      const response = await axios.post(
        "https://server.ayurdrishti.com/api/contact/submit",
        formData
      );
      if (response.status === 200) {
        setIsSubmitted(true);
        setFormData({ name: "", email: "", contact: "", message: "" });

        // Show success message with SweetAlert
        Swal.fire({
          icon: "success",
          title: "Message sent successfully!",
          text: "",
          confirmButtonText: "OK",
          customClass: {
            title: "swal-title",
            content: "swal-content",
          },
          iconColor: "#004d40",
          background: "#e0f7fa", // Background color for the popup
          confirmButtonColor: "#00695c", // Confirm button color
          padding: "10px", // Padding around the content
          willOpen: () => {
            const swalPopup = document.querySelector(".swal2-popup");
            if (swalPopup) {
              swalPopup.style.borderRadius = "15px";
              swalPopup.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)";
            }
            const titleElement = document.querySelector(".swal2-title");
            if (titleElement) {
              titleElement.style.color = "#004d40"; // Green color for the title text
            }
          },
        });
      }
    } catch (error) {
      setFormError("Failed to send message. Please try again later.");
      console.error("Submission error:", error);
    }
  };

  return (
    <CoverLayout image={bgImage}>
      <Card sx={{ maxWidth: { xs: "90%", sm: "70%", md: "500px" }, mx: "auto", padding: 2, mb: 4 }}>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Contact Us
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            For further questions, including partnership opportunities, please email
            ayurdrishti@gmail.com or contact us using the form below.
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <form onSubmit={handleSubmit}>
            <MKBox width="100%" method="post" autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <MKInput
                    variant="standard"
                    label="Full Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKInput
                    type="email"
                    variant="standard"
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    type="text"
                    variant="standard"
                    label="Contact"
                    name="contact"
                    value={formData.contact}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    variant="standard"
                    label="What can we help you with?"
                    placeholder="Describe your problem"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    multiline
                    fullWidth
                    rows={6}
                  />
                </Grid>
              </Grid>
              {formError && (
                <MDTypography color="error" variant="caption" display="block" mt={1}>
                  {formError}
                </MDTypography>
              )}
              <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                <MKButton type="submit" variant="gradient" color="info">
                  Send Message
                </MKButton>
              </Grid>
            </MKBox>
          </form>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Basic;
