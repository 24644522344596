import axios from "axios";

// Create a new Axios instance with your base URL
const api = axios.create({
  baseURL: "https://server.ayurdrishti.com/api", // Adjust this to your API's base URL
});

// Function to create an article with a file upload
export const createArticle = async (articleData, file) => {
  try {
    const formData = new FormData();

    // Append the file (assuming 'file' is the file object from input)
    if (file) {
      formData.append("file", file);
    }

    // Remove the 'file' property from articleData if it exists
    const { file: _, ...articleWithoutFile } = articleData;

    // Append the article data as a JSON string without the file
    formData.append("article", JSON.stringify(articleWithoutFile));

    // Send the request to the backend (with the correct headers for file upload)
    const response = await api.post("/articles", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data; // Return the created article data
  } catch (error) {
    throw error.response?.data || error.message; // Throw the error for handling in the component
  }
};

// Function to create an author
export const createAuthor = async (authorData) => {
  try {
    const response = await api.post("/authors", authorData);
    return response.data; // Return the created author data
  } catch (error) {
    throw error.response?.data || error.message; // Throw the error for handling in the component
  }
};

// Function to fetch all authors
export const fetchAuthors = async () => {
  try {
    const response = await api.get("/authors");
    return response.data; // Return the list of authors
  } catch (error) {
    throw error.response?.data || error.message; // Throw the error for handling in the component
  }
};

// Add more API methods as needed...

export default {
  createArticle,
  createAuthor,
  fetchAuthors,
};
