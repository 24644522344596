import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Typography, Box } from "@mui/material";

// Function to generate random colors
const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// Function to check if the color is light or dark
const isDarkColor = (color) => {
  const hex = color.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luminance < 128;
};

const ArticleCard = ({ title, publishedDate }) => {
  const formatDate = (publishedDate) => {
    const date = new Date(publishedDate);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const randomColor = getRandomColor();
  const textColor = isDarkColor(randomColor) ? "#ffffff" : "#000000";

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "16px",
        marginBottom: "24px",
        borderRadius: "10px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.3s ease",
        "&:hover": { transform: "translateY(-5px)" },
      }}
    >
      <CardContent sx={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            textAlign: "center",
            backgroundColor: "#f5f5f5",
            padding: "12px",
            borderRadius: "8px",
            marginRight: "16px",
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            {formatDate(publishedDate).split(" ")[0]}
          </Typography>
          <Typography variant="subtitle1" sx={{ textTransform: "uppercase", color: "#888" }}>
            {formatDate(publishedDate).split(" ")[1]}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="h6"
            sx={{
              backgroundColor: randomColor,
              color: textColor,
              textTransform: "none",
              padding: "8px 16px",
              fontWeight: "bold",
              borderRadius: "8px",
            }}
          >
            {title}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

ArticleCard.propTypes = {
  title: PropTypes.string.isRequired,
  publishedDate: PropTypes.string.isRequired,
};

export default ArticleCard;
