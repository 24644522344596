import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MDTypography from "components/MDTypography";
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";
import team1 from "assets/images/60111.jpg";
import team2 from "assets/images/60111.jpg";
import team3 from "assets/images/60111.jpg";
import team4 from "assets/images/60111.jpg";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-0.5}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MDTypography
              fontWeight="bold"
              sx={{
                fontSize: {
                  sm: "2rem",
                },
                textAlign: "left",
                color: "#FFDD44",
              }}
            >
              Meet Editorial Members
            </MDTypography>
            <MDTypography
              fontWeight="medium"
              sx={{
                fontSize: {
                  xs: "0.875rem",
                },
                textAlign: "left",
                color: "#D3D3D3",
                opacity: 0.8,
              }}
            >
              Our editorial team is dedicated to upholding the highest standards of quality and
              integrity in every article. With diverse expertise, they meticulously review
              submissions to ensure a rigorous publication process.
            </MDTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Dr. Amit Diwakar"
                position={{ color: "info", label: "MD Kayachikitsa" }}
                description={
                  <>
                    <div>
                      <strong>Location:</strong> United States of America
                    </div>
                    <div>
                      <strong>Contact:</strong> +1 607-339-6617
                    </div>
                  </>
                }
              />
            </MKBox>
          </Grid>

          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Dr. Milind Babarao Aware"
                position={{ color: "info", label: "MD Samhita Siddhant" }}
                description={
                  <>
                    <div>
                      <strong>Location:</strong> Mahalaxmi Nagar, Hirawadi Road Panchvati, Nashik,
                      Maharashtra
                    </div>
                    <div>
                      <strong>Contact:</strong> +91 96657 66600
                    </div>
                  </>
                }
              />
            </MKBox>
          </Grid>

          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team3}
                name="Dr. Kiran Pandurang Shinde"
                position={{ color: "info", label: "MD Kayachikitsa" }}
                description={
                  <>
                    <div>
                      <strong>Location:</strong> 745 MHB colony, Satpur, Nashik 12
                    </div>
                    <div>
                      <strong>Contact:</strong> 8087370701
                    </div>
                  </>
                }
              />
            </MKBox>
          </Grid>

          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team4}
                name="Dr. Pritam Suryabhan Pawale"
                position={{ color: "info", label: "MD Dravyaguna" }}
                description={
                  <>
                    <div>
                      <strong>Location:</strong> Professor Colony, Nashik, Maharashtra, India
                    </div>
                    <div>
                      <strong>Contact:</strong> +91 84129 78434
                    </div>
                  </>
                }
              />
            </MKBox>
          </Grid>

          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Dr. Vijay Raghunath Potkule"
                position={{ color: "info", label: "MD Kriya Sharir" }}
                description={
                  <>
                    <div>
                      <strong>Location:</strong> Avadhut Nagar, Rasbihari link road, Panchvati,
                      Nashik
                    </div>
                    <div>
                      <strong>Contact:</strong> +91 75583 97941
                    </div>
                  </>
                }
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
